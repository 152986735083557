import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout';
import HubspotForm from 'react-hubspot-form'
import Seo from '../components/seo';

export const query = graphql`
    query($id: ID!) {
        wpcontent {
            industryReport(id: $id) {
                title
                uri
                content
                reportCoverImage
                reportSlide1
                excerpt
                pointers
                hsFormId
                seo {
                    metaDesc
                    canonical
                    title
                    opengraphTitle
                    opengraphDescription
                    opengraphType
                    twitterTitle
                    twitterDescription
                }
            }
        }
    }
`

const IndustryReportTemplate = ({ data }) => {
    // console.log(data)
    const industryreport = data.wpcontent.industryReport

    const metaDesc = data.wpcontent.industryReport.seo.metaDesc
    const pageTitle = data.wpcontent.industryReport.seo.title
    let uri = data.wpcontent.industryReport?.uri
    
    let baseUrl = "https://www.vantagecircle.com";

    let pageUri = baseUrl + uri
    // const pageType = data.wpcontent.industryReport.seo.opengraphType

    // let pageurl = `https://www.vantagecircle.com`
    // const uri = industryreport.uri
    // if(uri){
    //     pageurl+= `${uri}`
    // }

    return (

        <Layout className="webinar-detail">
            <Seo title={pageTitle}
                description={metaDesc}
                link={[
					{
						rel: `canonical`,
						href: pageUri,
					},
				]}
            />
            <section className="bg-indigo-100 mx-auto py-10 sm:py-20 sm:pb-0 relative z-10">
                <div className="container max-w-7xl px-6 sm:px-6 lg:px-6">
                    <div className="site-breadcrumb mb-5 text-white opacity-80 text-center">
                        <Link className="text-white text-sm hover:underline hover:text-white transition duration-500 ease-in-out" to="/">Home</Link>&nbsp;&gt;&gt;&nbsp;
                        <Link className="text-white text-sm hover:underline hover:text-white transition duration-500 ease-in-out" to="/hr-academy/industry-reports/">Industry Reports</Link>&nbsp;&gt;&gt;&nbsp; 
                        <span className="text-sm" dangerouslySetInnerHTML={{ __html: industryreport.title }} />
                    </div>
                    <h1 className="max-w-5xl mx-auto hero-title-1-white text-center" dangerouslySetInnerHTML={{ __html: industryreport.title }} />
                    <p className="max-w-4xl mx-auto section-subtitle-white text-center" dangerouslySetInnerHTML={{ __html: industryreport.excerpt }} style={{marginLeft:"auto", marginRight:"auto"}}/>
                    <div className="md:flex mt-10 md:mt-20 pb-10 md:flex-row-reverse">
                        <div id="download-now" className="card-1 w-full bg-white md:w-1/2 text-gray-900 rounded shadow-lg p-8 lg:p-10 md:ml-28">
                            <div className="form-section">
                            <h2 className="mt-2 mb-8 text-2xl font-semibold text-gray-900 sm:text-3xl">Fill up to download your copy now</h2>
                                <HubspotForm
                                    portalId='6153052'
                                    formId={industryreport.hsFormId}
                                    onSubmit={() => console.log('Submit!')}
                                    onReady={(form) => console.log('Form ready!')}
                                    loading={<div>Loading...</div>}
                                />

                                {/* <div className="notetext text-center font-normal text-sm">We safeguard your personal information in accordance with our <a href="/privacy-policy/" target="_blank" rel="noopener">Privacy Policy</a>.</div> */}
                            </div>
                        </div>
                        <div className="card-1 w-full md:w-1/2 mb-10 md:mb-0 mt-5">
                            <p className="text-xl md:text-2xl text-white font-bold pt-5">You will get to learn</p>
                            <ul className="list-inside orangebullets text-white" dangerouslySetInnerHTML={{ __html: industryreport.pointers }}/>
                        </div>
                    </div>
                </div>
            </section>
            <div className="bg-purple-50 px-6 toc pt-10 py-0 md:pb-10 md:pt-40 toc-detail mb-10">
                <div className="container max-w-3xl">
                    <div dangerouslySetInnerHTML={{ __html: industryreport.content }} />
                </div>
            </div>
            <section className="bottom-cta m-6 md:m-0 relative z-1">
                <div className="container max-w-6xl bg-purple-300 rounded-lg shadow-xl relative z-9 overflow-hidden my-20">
                    <div className="md:flex">
                        <div className="p-10 md:pl-32 md:pr-0 bottom-cta-content text-white md:w-2/3 w-full">
                            <div className="section-title-white text-center md:text-left">The <span className="text-orange">AIRᵉ</span> Framework</div>
                            <p className="section-subtitle-white">A must have for <strong>Future-Proof</strong> hybrid workplace</p>
                            <div className="mt-5 sm:mt-8 md:mt-8 flex justify-center md:justify-start">
                                <div className="">
                                    <a href="/hr-academy/aire-framework-employee-recognition/" className="vc-ghost-btn-cta-small-banner">
                                        Know more
                                    </a>
                                </div>
                            </div> 
                        </div>
                        <div className="w-1/3 relative mob">
                            <img loading="lazy" className="absolute bottom-0" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/08/AIRe-footer-illus.png" alt="AIRe Framework"/>
                        </div>
                    </div>
                    <img loading="lazy" className="cta-dot-clip" src="https://res.cloudinary.com/vantagecircle/image/upload/v1622890994/gatsbycms/uploads/2021/06/circle-white-compressed.png"  alt="VC white dots" width="90" height="90"/>
                    <img loading="lazy" className="cta-flower-clip" src="https://res.cloudinary.com/vantagecircle/image/upload/v1622874257/gatsbycms/uploads/2021/06/vc-flower.svg"  alt="VC flower" width="150" height="150"/>
                </div>                         
            </section>
        </Layout>
    )
}

export default IndustryReportTemplate

